const images = [
	{
		id: 1,
		src: '/images/nightMarket/nightMarket1.jpg',
	},
	{
		id: 2,
		src: '/images/nightMarket/nightMarket2.jpg',
	},
	{
		id: 3,
		src: '/images/nightMarket/nightMarket3.jpg',
	},
	{
		id: 4,
		src: '/images/nightMarket/nightMarket4.jpg',
	},
	{
		id: 5,
		src: '/images/nightMarket/nightMarket5.jpg',
	},
];

export default images;
