const images = [
	{
		id: 1,
		src: '/images/fishMarket/fishMarket1.jpg',
	},
	{
		id: 2,
		src: '/images/fishMarket/fishMarket2.jpg',
	},
	{
		id: 3,
		src: '/images/fishMarket/fishMarket3.jpg',
	},
	{
		id: 4,
		src: '/images/fishMarket/fishMarket4.jpg',
	},
	{
		id: 5,
		src: '/images/fishMarket/fishMarket5.jpg',
	},
	{
		id: 6,
		src: '/images/fishMarket/fishMarket6.jpg',
	},
	{
		id: 7,
		src: '/images/fishMarket/fishMarket7.jpg',
	},
	{
		id: 8,
		src: '/images/fishMarket/fishMarket8.jpg',
	},
	{
		id: 9,
		src: '/images/fishMarket/fishMarket9.jpg',
	},
	{
		id: 10,
		src: '/images/fishMarket/fishMarket10.jpg',
	},
	{
		id: 11,
		src: '/images/fishMarket/fishMarket11.jpg',
	},
	{
		id: 12,
		src: '/images/fishMarket/fishMarket12.jpg',
	},
	{
		id: 13,
		src: '/images/fishMarket/fishMarket13.jpg',
	},
	{
		id: 14,
		src: '/images/fishMarket/fishMarket14.jpg',
	},
	{
		id: 15,
		src: '/images/fishMarket/fishMarket15.jpg',
	},
	{
		id: 16,
		src: '/images/fishMarket/fishMarket16.jpg',
	},
	{
		id: 17,
		src: '/images/fishMarket/fishMarket17.jpg',
	},
];

export default images;
